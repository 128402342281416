<template>
  <div class='equipment' id='ruleWrapper'>
    <div class='e_content bgFFF'>
      <div class='ruleMessage1'>
        <h2 class='title'>
          <div class="title_icon">
          </div>
          <span>添加活动</span>
        </h2>
        <el-form :model="formInline" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <div class='f-top'>
            <el-form-item :label="$t('searchModule.Event_Name')" required>
              <el-input type="text" placeholder="请输入活动名称" v-model.trim="formInline.activityName" auto-complete="off" style='width:250px' maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="关联优惠券" required>
              <ticket-item ref='ticketFrom' v-model="formInline"></ticket-item>
            </el-form-item>
            <el-form-item label="发放群组" required>
              <el-select v-model="formInline.publishGroup">
                <el-option label="按用户标签发放" :value="0"/>
                <el-option label="按手机号发放" :value="1"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formInline.publishGroup === 0">
              <el-select v-model="formInline.tags">
                <el-option
                  :label="item.labelName"
                  :value="item.labelId"
                  v-for="item in labelList"
                  :key="item.labelId"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="formInline.publishGroup === 1">
              <el-button type="primary" @click="dialogVisible=true">批量{{ $t('button.import') }}</el-button>
              <el-dialog
                title="批量导入"
                :visible.sync="dialogVisible"
                width="600px"
              >
                <div>1.请先获取批量导入的Excel模板将需要添加的信息填写后进行上传该Excel文件，
                  上传非规范文件可能造成数据错误，<el-button type='text' @click='exportModel'>点击下载</el-button>模板文件。
                </div>
                <div>
                    2.请选择上传文件，并上传。
                </div>
                <div style='margin-top:20px;'>
                  <el-upload
                    action=""
                    :on-remove="handleRemove"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :http-request="handleFiles"
                    :file-list="fileList"
                  >
                    <el-button size="small" type="primary">浏览</el-button>
                  </el-upload>
                </div>
                <div slot="footer" class="dialog-footer" style='text-align:center'>
                  <el-button type="primary" @click="dialogVisible=false">上传</el-button>
                  <el-button @click="dialogVisible=false">取 消</el-button>
                </div>
              </el-dialog>
            </el-form-item>
            <el-form-item label="推送通知" required>
              <el-checkbox-group v-model="formInline.couponPushMode">
                <el-checkbox :label="0">车主端</el-checkbox>
                <el-checkbox :label="1">短信推送</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div style='margin:0 auto;width:250px;margin-bottom:20px;'>
        <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
  import ticketItem from "@/components/ticketAddItem/index1"
  import { exportExcelNew } from "@/common/js/public.js";
  export default {
    components: {
      ticketItem
    },
    data () {
      return {
        formInline: {
          activityName: '',
          couponRuleActivities: [{
            couponRuleId: '',
            onceReceive: 1,
            publishAmount: ''
          }],
          publishGroup: 0,
          tags: '',
          couponPushMode: []
        },
        labelList: [],
        fileList: [],
        dialogVisible: false,
        loading: false
      }
    },
    methods: {
      getLabelList () {
        const opt = {
          url: '/acb/2.0/labelManage/labelList',
          method: 'get',
          data: { pageNum: 1, pageSize: 100 },
          success: res => {
            this.labelList = res.value.list
          }
        }
        this.$request(opt)
      },
      handleRemove(file, fileList) {
        this.fileList = []
      },
      handleExceed(files, fileList) {
        this.$message.warning(`只能上传一个文件`)
      },
      handleFiles (file) {
        this.fileList.push({ name: file.file.name })
        this.formInline.file = file.file
      },
      exportModel () {
        exportExcelNew('/acb/2.0/distributeActivity/downloadExcelTemplate', {});
      },
      checkData () {
        const data = this.formInline
        if (!data.activityName) {
          this.$alert('请输入活动名称', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
          return false
        }
        const umptyItem = data.couponRuleActivities.filter(item => {
          return !item.couponRuleId || !item.onceReceive || !/^[1-9]$/.test(item.onceReceive)
        })
        if (umptyItem.length > 0) {
          this.$alert('关联优惠券信息不能为空，且派发张数为1-9', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
          return false
        }
        if (data.publishGroup === 0 && !data.tags) {
          this.$alert('请选择用户标签', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
          return false
        }
        if (data.publishGroup === 1 && !data.file) {
          this.$alert('请导入手机号', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
          return false
        }
        if (data.couponPushMode && data.couponPushMode.length === 0) {
          this.$alert('请选择推送方式', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
          return false
        }
        return true
      },
      submit () {
        if (!this.checkData()) {
          return
        }
        this.loading = true
        const file = new FormData()
        const baseUrl = '/acb/2.0/distributeActivity/create'
        let params = '?activityName=' + this.formInline.activityName
          + '&publishGroup=' + this.formInline.publishGroup
          + '&couponRuleActivities=' + JSON.stringify(this.formInline.couponRuleActivities)
          + '&couponPushMode=' + this.formInline.couponPushMode.join(',')
        if (this.formInline.publishGroup === 0) {
          params += '&tags=' + this.formInline.tags
        } else {
          file.append('file', this.formInline.file)
        }
        const opt = {
          method: 'post',
          url: encodeURI(baseUrl + params),
          data: file,
          config: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },
          success: res => {
            this.$router.go(-1)
          },
          fail: res => {
            this.loading = false
          }
        }
        this.$request(opt)
      }
    },
    mounted () {
      this.getLabelList()
    }
  }
</script>
<style scoped>
    .tooltip1 {
        position: absolute !important;
        left: -65px;
        top: 8px;
        z-index:1
    }
    .fuhao {
        display:inline-block;
        width:30px;
        line-height:30px;
        text-align: center;
        border-radius:16px;
        border:2px solid;
        border-color:blue;
        font-size: 30px;
        vertical-align: middle;
    }
    .equipment .e_content{
        border: 1px solid #c0ccda;
        border-radius: 4px;
        min-height: 550px;
        padding:0 20px;
        color: #48576a;
    }
    .equipment .e_content .f-top{
        width:700px;
        margin: 0 auto;
        margin-bottom: 80px;
    }
    .el-select {
        width:250px;
    }
    .breadcrumb {
       height: 35px;
    }
    .ruleMessage {
        padding:10px;
        border:1px solid #e1e1e1;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .title {
        padding-top: 20px;
    }
    .timerPiker {
        padding-left:20px;
        position: relative;
    }
    .timerPiker .errMsg {
        position:absolute;
        left: 120px;
        top:39px;
        font-size:12px;
        color: #ff4949
    }
    .effectDate {
        margin-right:10px;
    }
    .dateForm {
        width:700px;
        margin:0 auto;
    }
    b {
        text-align:center;
        font-weight: normal;
        display: inline-block;
        width: 36px;
        line-height: 36px;
        background: #c0ccda;
    }
    .addEffictDate {
        display: inline-block;
        cursor: pointer;
    }
    .addEffictDate:hover {
        color: blue;
    }
    .help {
        display: inline-block;
        border-radius:10px;
        text-align:center;
        color: white;
        width: 20px;
        line-height: 20px;
        vertical-align: middle;
        background: #c0ccda;
        position: relative;
    }
    .timeMax label{
        padding: 0 !important;
        line-height: 16px !important;
    }
  ul.el-upload-list{
    display: inline-block;
  }
</style>
<style>
    .timeMax label{
        /* padding: 0 !important; */
        line-height: 18px !important;
    }
</style>
