<template>
  <el-form ref='form' :model="formInline">
    <el-form-item
      :class="{marginSty: ind !== formInline.couponRuleActivities.length - 1}"
      label=""
      :prop="'couponRuleActivities['+ind+'][couponRuleId]'"
      :rules="[{required: true, message: '请选择优惠券', trigger: 'change'}]"
      :key='ind'
      v-for='(val, ind) in formInline.couponRuleActivities'
    >
      <el-select @change='ticketChange' v-model="val.couponRuleId" placeholder="请选择" style="width: 180px;">
        <!-- <el-option :disabled='item.disabled && item.disabled' v-for="item in tenantLists" :key="item.couponRuleId" :label="item.couponName+' 面值'+item.faceValue/100+'元 满'+item.threshold/100+'元及以上且欠费不超过'+item.debtHours*24+'小时使用'" :value="item.couponRuleId"/> -->
        <el-option :disabled='item.disabled && item.disabled' v-for="item in tenantLists" :key="item.couponRuleId" :label="item.couponName" :value="item.couponRuleId"/>
      </el-select>
      <!-- <el-select  v-model="val.onceReceive"  placeholder="请选择" style="width: 70px;margin-left: 10px">
        <el-option  v-for="item in 10" :key="item" :label="item" :value="item"/>
      </el-select> -->
      <!-- 张/次 共 -->
      <el-input v-model="val.onceReceive" style="width:70px;display: inline-block;margin-left: 20px"/>
       张 
      <span id='addTicket' v-if='ind == 0' @click='addTicket'>+</span><span v-else id='addTicket' @click='deleteTicket(ind)'>-</span>
  </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      tenantLists: [],
      formInline: {}
    }
  },
  watch: {
    value: {
      handler(ne, old) {
        this.formInline = ne;
        this.$nextTick(() => {
            this.ticketChange()
        })
      },
      immediate: true,
      deep: true
    },
    formInline: {
      handler(ne, old) {
       this.$emit('input', ne)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    deleteTicket(ind) {
      let couponRuleActivities = this.formInline.couponRuleActivities;
      couponRuleActivities.splice(ind, 1);
    },
    // 获取优惠券
    couponRuleIds () {
      const opt = {
        method: 'get',
        url: '/acb/2.0/coupon/getDistributeRuleList',
        data: {},
        success: (res) => {
          // const list = res.value.list.filter(item => {
          //   return item.effectEndTime > this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          // })
          this.tenantLists = res.value
        }
      }
      this.$request(opt)
      // TODO
      // this.tenantLists = [
      //   {
      //     couponRuleId: 1,
      //     couponName: '测试规则',
      //     money: 1000,
      //     lowestMoney: 10000,
      //     debtHours: 1
      //   },
      //   {
      //     couponRuleId: 2,
      //     couponName: '测试规则',
      //     money: 1000,
      //     lowestMoney: 10000,
      //     debtHours: 1
      //   }
      // ]
    },
    ticketChange() {
      let couponRuleActivities = this.formInline.couponRuleActivities.reduce((pre, next) => {
        pre.push(next.couponRuleId);
        return pre;
      }, []);
      let newSet = new Set(couponRuleActivities);
      this.tenantLists = this.tenantLists.map(val => {
        if (!newSet.has(val.couponRuleId)) {
          val.disabled = false;
        } else {
          val.disabled = true;
        }
        return val
      })
    },
    addTicket() {
        if (this.formInline.couponRuleActivities.length >= 10) {
          this.$alert('最多添加10条！', this.$t('pop_up.Tips'), {
            type: 'warning'
          })
          return;
        }
        this.formInline.couponRuleActivities.push({
          couponRuleId: '',
          onceReceive: '',
          publishAmount: ''
        })
      },
    validate() {
      let flag = true;
      this.$refs.form.validate((valid) => { flag = valid });
      return flag;
    }
  },
  mounted() {
    this.couponRuleIds();
  },
}
</script>
<style scoped lang="stylus">
.marginSty
    margin-bottom: 20px;
#addTicket
    cursor: pointer;
    font-size: 40px;
    color: #0f6eff;
    display:inline-block;
    vertical-align: top;
    margin-top: -3px;
    width: 40px;
    text-align: center;
</style>
